.contactForm {
  width: 50rem;
  height: 55rem;
  background-color: rgba(255, 255, 255, 0.5);
  margin: auto;
  margin-top: 100px;
  padding-top: 2rem;
}
.contact-description {
  line-height: 1.4;
}
h3 {
  text-decoration: none;
}

/* Styling for each text field and animation*/
* {
  outline: none;
  font-family: Questrial;
}
.form-field {
  margin: 0px 4rem 0px 4rem;
  position: relative;
}
.input-data input,
.textarea textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 1rem;
  border: none;
  font-size: 1.4rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
  margin-bottom: 3rem;
  background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 600px) {
  .input-data input {
    width: 90%;
  }
}

.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-2.5rem);
  font-size: 15px;
  color: #3498db;
  left: 0.2rem;
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  font-size: 15px;
  transition: all 0.3s ease;
  left: 0.2rem;
  bottom: 10px;
  color: rgba(57, 57, 57, 0.5);
}
.input-data label:after {
  content: " *";
  color: rgb(203, 0, 0);
}

.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  left: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 105%;
  background: #0c84f4;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  left: 0;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
@media (min-width: 600px) {
  /* Styling for name row*/
  .form_row_with_grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50% 50%;
    height: 4rem;
    margin-bottom: 2rem;
  }

  #firstname-input,
  #lastname-input,
  #address-input,
  #city-input,
  #zipcode-input {
    position: relative;
    display: block;
  }
  #firstname-input:focus ~ label,
  #lastname-input:focus ~ label,
  #address-input:focus ~ label,
  #city-input:focus ~ label,
  #zipcode-input:focus ~ label {
    transform: translateY(-2.9rem);
  }

  #firstname-underline,
  #lastname-underline,
  #address-underline,
  #city-underline,
  #zipcode-underline {
    position: absolute;
    bottom: 3rem;
    height: 2px;
    width: 112%;
  }

  #firstname-underline:before,
  #lastname-underline:before,
  #address-underline:before,
  #city-underline:before,
  #zipcode-underline:before {
    width: 100%;
  }

  #firstname-label,
  #lastname-label,
  #address-label,
  #city-label,
  #zipcode-label {
    bottom: 3.3rem;
  }
}
#state-selection {
  position: relative;
  width: 100%;
  height: 3rem;
  border: none;
  font-size: 1.4rem;
  color: black;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0);
}
#state-select-div select {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}
#state-selection option {
  background-color: rgba(0, 0, 0, 0%);
  text-decoration: none;
}
#state-selection:hover {
  cursor: pointer;
}

/*styling for button*/
.submitbtn {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  padding: 0.8rem;
  border: none;
  color: white;
  background-color: rgb(165, 165, 165);
}
.submitbtn:hover {
  background-color: #0c84f4;
  transition-duration: 0.5s;
  color: white;
  transform: scale(1.1);
}
.submitbtn:active {
  background-color: #0b68c0;
  transform: scale(0.95);
}

@media (max-width: 600px) {
  .contactForm {
    width: 100%;
    height: 70rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin: auto;
    margin-top: 50px;
    padding-top: 2rem;
  }
  .form_row_with_grid {
    display: block;
  }
  .contact-description {
    margin-bottom: 30px;
  }
  .input-data .underline:before {
    transition: transform 0.5s ease;
  }
  #state-select-div select {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }
  .input-data .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    left: 0;
  }
  .contact-description {
    padding: 0 1.5rem 0 1.5rem;
  }
}
