.app-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #9fccff;
}
.app_background {
  height: 100%;
  position: fixed;
  top: -1000px;
}
.dot1 {
  height: 1400px;
  width: 1400px;
  background-color: #e7f2ff;
  border-radius: 50%;
  position: absolute;
  top: 1700px;
  left: -700px;
  animation-name: dot-pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
.dot2 {
  height: 2400px;
  width: 2400px;
  background-color: #cfe5ff;
  border-radius: 50%;
  position: absolute;
  top: 1200px;
  left: -1000px;
  animation-name: dot-pulse;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
.dot3 {
  height: 3570px;
  width: 3570px;
  background-color: #b7d9ff;
  border-radius: 50%;
  position: absolute;
  top: 600px;
  left: -1400px;
  animation-name: dot-pulse;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;
}
@keyframes dot-pulse {
  from {
    transform: scale(95%);
  }

  to {
    transform: scale(100%);
  }
}

@media (max-width: 600px) {
  .dot1 {
    height: 1000px;
    width: 1000px;
    top: 1600px;
    left: -700px;
  }
  .dot2 {
    height: 1850px;
    width: 1850px;
    top: 1050px;
    left: -1025px;
  }
  .dot3 {
    height: 2775px;
    width: 2775px;
    top: 600px;
    left: -1500px;
  }
}
