.product-container-child {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem; /* Spacing between items (optional) */
  margin: 3rem;
  justify-content: space-evenly;
  justify-items: left;
  align-content: space-evenly;
  align-items: left;
}

.left-container-child {
  display: flex;
  flex-direction: column;
  width: 500px; /* Set the width of each image */
  padding: 10px; /* Add padding around the container */
  margin: 0.5rem;
}

.right-container-child {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  max-width: 700px;
}

.specs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* flex-shrink: auto; */
  width: 90%;
  margin: 1rem;
  text-align: left;
  list-style-type: disc;
}

/*styling for button*/
.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
button {
  width: 200px;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  padding: 0.8rem;
  border: none;
  color: white;
  background-color: #0c84f4;
  top: 50%;
  font-size: 1rem;
}
button:hover {
  background-color: #0464bd;
  transition-duration: 0.5s;
  color: white;
  transform: scale(1.07);
}
button:active {
  background-color: #0b68c0;
  transform: scale(0.95);
}
