.content {
  position: relative;
  margin-top: 100px;
}

.parallax-container {
  background-image: url("../../assets/images/home/parallax-background.png");
  position: relative;
  height: 800px;
  background-attachment: fixed;
  background-position: 55% 45%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 100px;
}

.background-image {
  width: 100%;
  height: auto;
}

.text-overlay {
  position: absolute;
  bottom: 15%;
  width: 500px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #c9c9c9; /* Text color */
  justify-content: left;
  text-align: justify;
  padding: 10px 50px 10px 50px;
  margin: auto;
}
.text-overlay p {
  font-size: 22px;
  width: 450px;
  margin-left: 3rem;
}

/*information section*/
.information-section {
  margin-bottom: 100px;
}
.information-section-grid {
  display: grid;
  grid-template-columns: minmax(450px, 600px) minmax(450px, 600px);
  height: 100%;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  margin: 0px 200px 0px 200px;
  gap: 2rem;
}
.info-section-img {
  filter: drop-shadow(6px 0px 6px rgba(47, 47, 47, 0.5));
  width: 400px;
}

.info-section-text-div {
  margin-bottom: 50px;
}
ul {
  text-align: left;
}

.info-section-list {
  justify-content: left;
}
.info-section-para,
.info-section-list {
  text-align: left;
  width: 95%;
  max-width: 480px;
}
/*Brands we support section*/
.brand-imgs-section {
  text-align: center;
  padding: 0px 50px 30px 50px;
  text-align: center;
  justify-content: center;
}
.brands-section {
  margin-bottom: 100px;
}
.brand-imgs {
  height: 30px;
  padding: 15px;
  flex-wrap: "hard";
  white-space: normal;
  display: inline-grid;
  place-items: center;
  margin: 0 auto;
}
#brand-img-hp {
  height: 50px;
  padding-bottom: 5px;
}
.button {
  text-decoration: none;
  color: #000;
}
.page-curl-button {
  padding-top: 20px;
  color: #000;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 20px;
}
.page-curl-button:hover {
  color: #0e86f7;
  transform: scale(103%);
  width: 100%;
}
.page-curl-button:active {
  color: #0a5398;
  transform: scale(0.95);
}
.page-curl-button h3 {
  color: #0c84f4;
  margin: 20px auto;
}

/*Contact Page Link corner page curl*/
#fpc_effect-back {
  height: 200px;
  background-color: #ffff; /* some background color to match corner inside's */
  justify-content: center;
  margin: 0 auto;
}
#fpc_effect-back * {
  box-sizing: border-box;
}
#fpc_box {
  width: 100%; /*any relative or absolute*/
  position: relative;
  background-color: #ffffff;
  color: #0c84f4;
}
#fpc_content {
  padding: 20px 0px 20px 160px;
}
#fpc_content:before {
  content: "";
  width: 160px;
  height: 200px;
  float: right;
}
#fpc_page-tip:before,
#fpc_page-tip:after {
  background-color: #fff;
  position: absolute;
  display: block;
  z-index: 2;
  border-top-right-radius: 30%;
  width: 100%;
  height: 100%;
  content: "";
}
#fpc_page-tip:before {
  right: 100%;
  top: 0%;
  background: -webkit-radial-gradient(
    -180% 200%,
    circle,
    rgba(255, 255, 255, 0) 85%,
    rgba(0, 0, 0, 0.1) 93%
  );
}
#fpc_box:hover #fpc_page-tip:before {
  border-right: solid 1px #fff;
}
#fpc_box div#fpc_corner-box:hover #fpc_page-tip:before {
  border-right: solid 2px #fff;
}
#fpc_page-tip:after {
  top: 100%;
  right: 0%;
  background: -webkit-radial-gradient(
    -250% 320%,
    circle,
    rgba(255, 255, 255, 0) 85%,
    rgba(0, 0, 0, 0.1) 93%
  );
}
#fpc_box:hover #fpc_page-tip:after {
  border-top: solid 1px #fff;
}
#fpc_box div#fpc_corner-box:hover #fpc_page-tip:after {
  border-top: solid 2px #fff;
}
#fpc_corner-box {
  /* edit these sizes for the default revealing corner size */
  height: 40px;
  width: 40px;
  right: 0;
  top: 0;
  position: absolute;
  overflow: visible;
}
#fpc_box:hover #fpc_corner-box {
  /* edit corner size (First animation, when the whole page is rollovered) */
  height: 100px;
  width: 100px;
}
#fpc_box:active #fpc_corner-box {
  /* edit corner size (First animation, when the whole page is rollovered) */
  height: 300px;
  width: 300px;
}

#fpc_corner-box:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  display: block;
  width: 133%;
  height: 133%;
}
#fpc_corner-contents:after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  background: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 37%,
      #ddd 62%,
      rgba(230, 230, 230, 0.1) 64%,
      rgba(255, 255, 255, 0) 67%
    ),
    -webkit-radial-gradient(-50% 150%, circle, transparent 74%, rgba(
            0,
            0,
            0,
            0.2
          )
          74%, transparent 81%);
  display: block;
  width: 133%;
  height: 133%;
}
#fpc_page-tip {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  background: -webkit-linear-gradient(
    45deg,
    #ddd 17%,
    #dfdfdf 18%,
    #f5f5f5 30%,
    #f8f8f8 34%,
    #eee 39%,
    rgba(200, 200, 200, 0) 41%
  );
  display: block;
  width: 100%;
  height: 100%;
}
#fpc_corner-button {
  position: absolute;
  width: 90px;
  height: 40px;
  top: 0;
  right: 0;
  background-color: #0c84f4;
  color: #fff;
  text-align: center;
  padding: 5px;
  border: none;
}
#fpc_corner-contents {
  width: 125%;
  position: absolute;
  display: block;
  overflow: hidden;
  -webkit-mask: -webkit-linear-gradient(45deg, transparent 49%, #000 53%);
  top: 0;
  right: 0;
  height: 125%;
}
#fpc_corner-contents:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #eeeef4; /* Match this background color to #fpc_effect-back */
}
#fpc_corner-box,
#fpc_corner-contents,
#fpc_page-tip {
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.35, 0.5, 1.7);
}
#fpc_corner-button strong {
  font-weight: bold;
  display: block;
}
/*Contact Page Link ^^^^ */

@media (max-width: 600px) {
  .text-overlay {
    bottom: 10%;
    width: 100%;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 25px 5px 25px;
    font-size: 13px;
  }
  .text-overlay p {
    font-size: 16px;
    width: 260px;
  }
  .parallax-container {
    height: 400px;
    top: -30px;
    background-position: 65% top;
    background-size: auto 470px;
    margin-bottom: 50px;
  }

  .brand-imgs {
    height: 15px;
    padding: 5px;
  }
  #brand-img-hp {
    height: 25px;
  }
  .brand-imgs-section {
    text-align: center;
    padding: 0px 30px 20px 30px;
    text-align: center;
    justify-content: center;
  }
  #fpc_effect-back {
    height: auto;
    background-color: #ffffff; /* some background color to match corner inside's */
    width: 100%; /* trivial */
  }
  #fpc_content {
    padding: 20px 0px 20px 100px;
    margin: 0px;
    justify-content: center;
  }
  #fpc_content:before {
    width: 100px;
    height: 200px;
  }

  #fpc_box:hover #fpc_corner-box {
    /* edit corner size (First animation, when the whole page is rollovered) */
    height: 50px;
    width: 50px;
  }
  #fpc_box div#fpc_corner-box:hover {
    /* edit corner size (Second animation, when the corner itself is rollovered) */
    height: 70px;
    width: 70px;
  }
  #fpc_box:active #fpc_corner-box {
    /* edit corner size (First animation, when the whole page is rollovered) */
    height: 200px;
    width: 200px;
  }

  #sub-title-text {
    padding: 0px 30px 0px 30px;
    margin-bottom: 30px;
  }
  .information-section-grid {
    grid-template-columns: 400px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    margin: auto;
  }
  .info-section-text-div:nth-child(3) {
    order: 4;
  }
  .info-section-text-div:nth-child(4) {
    order: 3;
  }
  .info-section-img {
    width: 300px;
  }
  .info-section-text-div {
    width: 300px;
  }
  .info-section-text-div:nth-child(n + 1) {
    grid-column: 1 / 2;
  }
  .info-section-para,
  .info-section-list {
    text-align: left;
    width: 100%;
    max-width: 480px;
  }
}
