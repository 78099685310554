.loadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: backgroundfade;
  animation-duration: 13s;
  -webkit-animation: backgroundfade;
  -webkit-animation-duration: 13s;
}

.introSVG {
  pointer-events: none;
  position: absolute;
  animation: fadeLogo;
  animation-duration: 13s;
  -webkit-animation: fadeLogo;
  -webkit-animation-duration: 13s;
}

@keyframes backgroundfade {
  0% {
    background-color: rgb(255, 255, 255);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes fadeLogo {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
