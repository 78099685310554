.content {
  margin-top: 100px;
}
.product-container-parent {
  display: block;
}
.left-container-parent {
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;
  width: auto;
  height: auto;
  gap: 1rem; /* Spacing between items (optional) */
}
.productSearch-parent {
  margin-top: 15px;
}

.productSearch-parent input {
  margin: 1rem;
  border: none;
  padding: 0.5rem;
  width: 20rem;
  background-color: white;
}

label {
  display: block;
}
span {
  align-items: center;
}

.productList {
  display: flex;
  margin: auto 5rem auto 5rem;
  padding: auto;
  flex-wrap: wrap;
  gap: 0.8rem; /* Add some spacing between images (adjust as needed) */
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-between;
  align-items: left;
}
.productListDetailsdiv {
  display: flex;
  height: 360px;
  width: 300px;
}
.productListDetails {
  list-style: none; /* Remove default list styles */
  display: inline-block;
  align-self: flex-end;
}
.prod_modelnum {
  word-wrap: break-word;
  max-width: 270px;
  white-space: initial;
}

.productListDetails:hover {
  background-color: rgba(1, 77, 153, 0.1);
  transition-duration: 0.5s;
  transform: scale(1.07);
}

.productListDetails img {
  width: 250px; /* Set the width of each image */
  max-height: 250px; /* Set the height of each image */
  object-fit: scale-down; /* Maintain the image aspect ratio and crop if needed */
}
