@font-face {
  font-family: "Questrial";
  src: url("./font/Questrial-Regular.eot");
  src: url("./font/Questrial-Regular.eot?#iefix") format("embedded-opentype"),
    url("./font/Questrial-Regular.woff2") format("woff2"),
    url("./font/Questrial-Regular.woff") format("woff"),
    url("./font/Questrial-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Questrial, sans-serif;
}

h1 {
  font-size: 34px;
  font-weight: normal;
}
h2 {
  font-size: 28px;
  font-weight: normal;
}
h3 {
  font-size: 22px;
  font-weight: normal;
}
h4 {
  font-size: 14px;
  font-weight: normal;
}
p,
li {
  font-size: 20px;
  line-height: 1.4;
}
input {
  font-size: 20px;
}

a:hover {
  cursor: pointer;
  color: #0c84f4;
}

@media (max-width: 600px) {
  h1 {
    font-size: 30px;
    font-weight: normal;
  }
  h2 {
    font-size: 24px;
    font-weight: normal;
  }
  h3 {
    font-size: 18px;
    font-weight: normal;
  }
  h4 {
    font-size: 12px;
    font-weight: normal;
  }
  p,
  li {
    font-size: 18px;
    line-height: 1.3;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
