/* Style for the header element */
.header {
  position: fixed;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 100; /* Ensures the header is displayed above other content */
}

/* Style for the logo */
.logo {
  cursor: pointer;
  align-items: left;
  list-style: none;
  width: 180px; /* Set your desired width */
  height: auto; /* Let the height adjust proportionally */
}

.tabs {
  align-items: center;
  gap: 50px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
}

.tabs li {
  align-items: right;
  text-decoration: none;
  font-size: 24px;
}

.tab {
  cursor: pointer;
  transition: color 0.2s;
  color: rgb(67, 67, 67);
}

.tab:hover {
  color: #0c84f4; /* Change the color on hover */
  transform: scale(1.1);
  font-weight: 500;
}

.tab.active {
  color: #0c84f4; /* Color for the active tab */
  font-weight: bold;
}

@media (max-width: 600px) {
  .header {
    width: 100%;
    height: 70px;
  }
  .logo {
    display: flex;
    align-items: left;
    list-style: none;
    width: 110px; /* Set your desired width */
    height: auto; /* Let the height adjust proportionally */
    margin: auto auto auto 5px;
  }
  .tabs {
    gap: 10px;
    margin: auto;
    padding: 0px;
  }
  .tabs li {
    align-items: left;
    text-decoration: none;
    font-size: 18px;
  }
}
